<template>
  <v-card>
    <v-toolbar
      flat
      color="transparent"
    >
      <v-text-field
        v-model="options.search"
        rounded
        dense
        outlined
        hide-details
        label="搜索"
        placeholder="用户名、手机号"
        :append-icon="icons.mdiMagnify"
        class="flex-grow-0"
        @click:append="getDesserts"
      />
      <v-spacer></v-spacer>
      <v-btn
        v-permission="['20001']"
        color="primary"
        dark
        @click="newUserDialog"
      >
        添加用户
      </v-btn>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="desserts"
      :items-per-page.sync="options.pageSize"
      :page.sync="options.page"
      :server-items-length="totalDesserts"
      :loading="loading"
      :disable-sort="true"
      :footer-props="{itemsPerPageOptions: [10, 15, 20, 50, 100]}"
      @update:items-per-page="getDesserts"
      @update:page="getDesserts"
    >
      <template v-slot:item.is_active="{ item }">
        <v-chip
          :color="item.is_active ? 'success' : 'error'"
          dark
        >
          {{ item.is_active ? '启用' : '禁用' }}
        </v-chip>
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ item.created_at | dateFormat }}
      </template>
      <template v-slot:item.updated_at="{ item }">
        {{ item.updated_at | dateFormat }}
      </template>
      <template v-slot:item.last_login="{ item }">
        {{ item.last_login | dateFormat }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          v-permission="['20003']"
          icon
          @click="editBtnClick(item)"
        >
          <v-icon>{{ icons.mdiPencil }}</v-icon>
        </v-btn>
        <v-btn
          v-permission="['20004']"
          icon
          @click="deleteBtnClick(item)"
        >
          <v-icon>{{ icons.mdiDelete }}</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog
      v-model="addUserDialog"
      max-width="500px"
    >
      <v-card class="pa-sm-10">
        <v-card-title class="justify-center text-h5">
          新增用户
        </v-card-title>
        <v-card-text class="mt-5">
          <v-form
            ref="userForm"
            v-model="userInfo"
            lazy-validation
          >
            <v-text-field
              v-model="adduser.username"
              :rules="[rules.required]"
              label="用户名称"
              validate-on-blur
              counter
              maxlength="10"
              dense
              outlined
              style="margin-bottom: 10px;"
            ></v-text-field>
            <v-text-field
              v-model="adduser.mobile"
              :rules="[rules.required]"
              label="手机号码"
              validate-on-blur
              counter
              maxlength="11"
              dense
              outlined
              style="margin-bottom: 10px;"
            ></v-text-field>
            <v-text-field
              v-model="adduser.password"
              :rules="[rules.required]"
              type="password"
              label="密码"
              validate-on-blur
              counter
              maxlength="20"
              dense
              outlined
              style="margin-bottom: 10px;"
            ></v-text-field>
            <v-select
              v-model="adduser.is_active"
              :items="stateOptions"
              item-text="text"
              item-value="value"
              persistent-hint
              required
              outlined
              dense
              label="请选择状态"
              style="margin-bottom: 10px;"
            ></v-select>
            <v-select
              v-model="adduser.role"
              :items="roleOptions.slice(1)"
              item-text="state"
              item-value="abbr"
              persistent-hint
              required
              outlined
              dense
              label="请选择角色"
              style="margin-bottom: 10px;"
            ></v-select>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-center mt-3">
          <v-btn
            color="success"
            dark
            @click="addUserSubmit"
          >
            确定
          </v-btn>
          <v-btn
            outlined
            @click="concelForm"
          >
            取消
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 删除弹框 -->
    <v-dialog
      v-model="deleteDialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          确定要删除这条数据吗?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="cancelDelete"
          >
            取消
          </v-btn>
          <v-btn
            color="success"
            @click="deleteItem"
          >
            确认
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- 编辑弹框 -->
    <v-dialog
      v-model="editDialog"
      max-width="600px"
    >
      <v-card>
        <v-card-title class="justify-center text-h5">
          用户编辑
        </v-card-title>
        <v-card-text class="mt-5">
          <v-form
            ref="userForm"
            v-model="editInfo"
            lazy-validation
          >
            <v-text-field
              v-model="edituser.username"
              :rules="[rules.required]"
              label="用户名称"
              validate-on-blur
              counter
              maxlength="10"
              dense
              outlined
              style="margin-bottom: 10px;"
            ></v-text-field>
            <v-text-field
              v-model="edituser.mobile"
              :rules="[rules.required]"
              label="手机号码"
              validate-on-blur
              counter
              maxlength="11"
              dense
              outlined
              style="margin-bottom: 10px;"
            ></v-text-field>
            <v-select
              v-model="edituser.is_active"
              :items="stateOptions"
              item-text="text"
              item-value="value"
              persistent-hint
              required
              outlined
              dense
              label="请选择状态"
              style="margin-bottom: 10px;"
            ></v-select>
            <v-select
              v-model="edituser.role"
              :items="roleOptions"
              item-text="state"
              item-value="abbr"
              persistent-hint
              required
              outlined
              dense
              label="请选择角色"
            ></v-select>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-center mt-3">
          <v-btn
            color="success"
            dark
            @click="editUserSubmit"
          >
            保存
          </v-btn>
          <v-btn
            outlined
            @click="editUserCancel"
          >
            取消
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import {
  getUsers,
  getRoles,
  addUser,
  deleteUser,
  editUser,
} from '@/api/user'
import {
  mdiMagnify,
  mdiClose,
  mdiDelete,
  mdiPencil,
} from '@mdi/js'

export default {
  name: 'Users',
  data() {
    return {
      desserts: [],
      totalDesserts: 0,
      loading: true,
      addUserDialog: false,
      deleteDialog: false,
      userInfo: true,
      editInfo: true,
      editDialog: false,
      userid: '',
      adduser: {
        username: '',
        mobile: '',
        password: '',
        role: '',
        is_active: '',
      },
      edituser: {
        username: '',
        mobile: '',
        role: '',
        is_active: true,
      },
      rules: {
        required: value => !!value || '必填.',
        counter: value => (value && value.length <= 20) || '最大20个字符',
      },
      stateOptions: [{
        text: '启用',
        value: true,
      },
      {
        text: '禁用',
        value: false,
      },
      ],
      roleOptions: [],
      headers: [{
        text: '用户名',
        value: 'username',
      },
      {
        text: '手机号',
        value: 'mobile',
      },
      {
        text: '角色',
        value: 'role_name',
      },
      {
        text: '状态',
        value: 'is_active',
      },
      {
        text: '创建时间',
        value: 'created_at',
      },
      {
        text: '最后登录时间',
        value: 'last_login',
      },
      {
        text: '操作',
        sortable: false,
        value: 'actions',
      },
      ],
      userrules: {
        required: value => !!value || '必填.',
        counter: value => (value && value.length <= 10) || '最大10个字符',
      },
      icons: {
        mdiMagnify,
        mdiClose,
        mdiDelete,
        mdiPencil,
      },
      options: {
        page: 1,
        pageSize: 10,
        search: '',
      },
    }
  },

  created() {
    this.getDesserts()
    this.getRole()
  },

  methods: {
    getDesserts() {
      this.loading = true
      getUsers(this.options)
        .then(response => {
          this.desserts = response.data.results
          this.totalDesserts = response.data.count
          this.loading = false
        })
    },

    // 获取角色列表
    getRole() {
      getRoles()
        .then(response => {
          console.log(response)
          for (let i = 0; i < response.data.results.length; i++) {
            const obj = {
              state: response.data.results[i].name,
              abbr: response.data.results[i].id,
            }
            this.roleOptions.push(obj)
          }
        })
    },

    // 新增用户弹框
    newUserDialog() {
      this.addUserDialog = true
    },

    // 添加用户
    async addUserSubmit() {
      if (!this.$refs.userForm.validate()) return
      console.log(this.adduser)
      await addUser(this.adduser)
      this.addUserDialog = false
      this.$toast.success('添加用户成功')
      this.getDesserts()
    },

    // 取消增加弹框
    concelForm() {
      this.addUserDialog = false
    },

    // 删除用户
    deleteBtnClick(item) {
      console.log(item.id)
      this.userid = item.id
      this.deleteDialog = true
    },

    // 取消删除
    cancelDelete() {
      this.deleteDialog = false
    },

    // 确认删除用户
    async deleteItem() {
      await deleteUser(this.userid)
      this.deleteDialog = false
      this.$toast.success('删除成功')
      this.options.page = 1
      this.getDesserts()
    },

    // 编辑用户
    editBtnClick(item) {
      this.editDialog = true
      console.log(item)
      this.edituser.username = item.username
      this.edituser.mobile = item.mobile
      this.edituser.role = item.role
      this.edituser.is_active = item.is_active
      this.userid = item.id
    },

    // 取消编辑
    editUserCancel() {
      this.editDialog = false
    },
    async editUserSubmit() {
      console.log(this.userid)
      console.log(this.edituser)
      await editUser(this.userid, this.edituser)
      this.editDialog = false
      this.$toast.success('编辑成功')
      this.getDesserts()
    },

  },
}
</script>
